import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Helmet from 'react-helmet'

import Layout from "../components/layout"
import SEO from "../components/seo"

import Facebook from "../images/facebook.svg";
import Instagram from "../images/instagram.svg";

const KontaktPage = () => (
  <Layout>
    <Helmet>
        <body id="kyoto-kotankt" />
    </Helmet>
    <SEO
        title="Kontaktieren Sie uns - Kyoto Sushi Bar Paderborn | Adresse, Telefon, E-Mail"
        description="Nehmen Sie Kontakt mit uns auf! Bei Kyoto Sushi Bar in Paderborn freuen wir uns, Ihnen behilflich zu sein. Erfahren Sie unsere Adresse, Telefonnummer und E-Mail, um Fragen zu stellen, Reservierungen vorzunehmen oder Feedback zu geben. Unser freundliches Team steht Ihnen zur Verfügung und beantwortet gerne Ihre Anliegen. Wir freuen uns darauf, von Ihnen zu hören und Sie in unserem Restaurant willkommen zu heißen."
        keywords="Kontakt, Kyoto, Sushi, japanisches Restaurant, Paderborn, Anschrift, Telefonnummer, E-Mail, Öffnungszeiten, Reservierung, Fragen, Anliegen, Feedback, Kundenservice, Team, Erreichbarkeit, Kontaktformular, Besuch, Information"
    />
    <section className="page">
        <div className="image md:mx-0">
            <StaticImage
            src="../images/karte-kyoto.png"
            layout={'fullWidth'}
            quality={80}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Kyoto Koch"
            className="block"
            />
        </div>
        <div class="content px-10">
            <div className="text-center py-5">
                <h1 className="text-2xl font-medium uppercase">Kontakt</h1>
                <h2 className="mb-4">Der schnellste Weg zum besten Sushi!</h2>
            </div>
            <div className="mb-5">
                <h3 className="text-gray-500 uppercase mb-2">Anschrift</h3>
                <address className="not-italic">
                    <p className="uppercase">Kyoto - Sushi Bar</p>
                    <p>Heiersstraße 37</p>
                    <p>33098 Paderborn</p>
                </address>
                <a className="block underline mt-2" target="_blank" href="https://www.google.com/maps/dir/51.7752338,8.9634786/kyoto+Paderborn/@51.72909,8.7290663,14.04z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x47ba4d3b95a37125:0x1086c31be4ebe972!2m2!1d8.7572083!2d51.7218659">
                    Navigieren Sie zu uns >
                </a>
            </div>
            <div className="mb-5">
                <h3 className="text-gray-500 uppercase mb-2">So erreichen Sie uns</h3>
                <p>Fon: <a href="tel:+4952514145982">05251 4145982</a></p>
                <p>E-Mail: <a href="mailto:info@vaan-lounge.de">info@vaan-lounge.de</a></p>
            </div>
            <div className="mb-5">
                <h3 className="text-gray-500 uppercase mb-2">Folgen sie uns auf social media</h3>
                <div className="inline-flex space-x-4">
                    <div className="flex-1"><a target="_blank" href="https://www.facebook.com/kyotopb/"><Facebook className="w-10" /></a></div>
                    <div className="flex-1"><a target="_blank" href="https://www.instagram.com/sushi_bar_kyoto_paderborn"><Instagram className="w-10" /></a></div>
                </div>
            </div>
        </div>
    </section>
  </Layout>
)

export default KontaktPage
